.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }

.nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    border-bottom: 2px solid red;
    border-radius: 0;
  }